import Vue from 'vue';

import './About.scss';
import Footer from '@/components/Shared/Footer/Footer';
import ClientNav from '@/components/Clients/ClientNav';

const About = Vue.extend({
  render() {
    return (
      <div>
        <ClientNav />

        <div class="about">

          <img data-src='https://res.cloudinary.com/nerdge/image/upload/q_auto,f_auto,fl_lossy,dpr_auto/v1582663237/Teefal%20Studios/section-1.png' class="lazyload about__image" alt="About" />

          <div>
            <div class="about__content">
              <h2 class="about__title">About</h2>
              <p class="about__description">We’re a team of talented, artistic photographers who work  to offer families and couples the very best in timeless photoshoots for weddings, maternity, newborn, cake smash and family photo sessions in Nigeria.</p>
            </div>
          </div>
        </div>

        <Footer />

      </div>
    );
  },
});

export default About;
